<template>
  <v-app>
    <v-banner
      single-line
      :height="screenHeight"
      class="banner"
    >
      <!-- <video ref="videos" id="video" loop="loop" muted="muted" preload="auto" autoplay="autoplay" poster="https://h5.ophyer.cn/official_website/banner/metaverse-h5-home-banner.png" type="video/mp4" src="" class="video"></video> -->
      <!-- <div class="video-up"></div> -->
       <!-- animate__animated animate__fadeInRightBig -->
 
      <v-img
        src="https://h5.ophyer.cn/official_website/other/metaverse-banner1-img3.png"
        width="100%" height="100%" cover
        :transition="false">
        <div class="banner-box" ref="bannerTitle" :class="addClass ? 'aaa' : ''">
          <p class="banner-title">飞天元宇宙介绍</p>
          <p class="banner-text">开放沉浸的3D虚拟世界，720度内部VR全景 <br> 运用你的想象力，让虚拟与现实完美融合</p>
        </div>
      </v-img>
      <div class="bottom"></div>
    </v-banner>

    <!-- 开启你的创意之旅 -->
    <v-card class="warps warp1 wow animate__fadeInUp" data-wow-duration="1s" data-wow-offset="10" :flat="true">
      <div class="top"></div>
      <div class="metaverse-title">
        <p>开启你的创意之旅</p>
      </div>
      <div class="con">
        <v-card class="mx-5" :flat="true" v-for="(v,k) in warp1" :key="k">
          <div class="div-filter"></div>
          <img :src="v.bg" />
          <div class="box">
            <v-card-title> {{ v.title }} </v-card-title>
            <v-card-text> {{ v.text }} </v-card-text>
            <div class="btn">
              <!-- <button v-if="v.btn" @click="currentHover = true"> {{ v.btn }} </button> -->
            </div>
          </div>
          <span class="more" v-if="v.more" @click="handleLink(v)">
            {{ v.more }} 
          </span>
        </v-card>
      </div>
    </v-card>

    <!-- 什么是飞天元宇宙 -->
    <v-card class="warps warp2" :flat="true">
      <div class="metaverse-title wow animate__fadeInUp" data-wow-duration="1s">
        <p>什么是飞天元宇宙</p>
        <p>飞天元宇宙是一个开放的，自由的的创意社区，由所有开发者们共同打造。将内容创造、社交、娱乐体验结合在一起，所有人一起创造，探索享受这个世界</p>
      </div>
      <div class="con">
        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img01-1.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>沉浸式体验</p>
            <p>庞大的3D虚拟世界场景，结合720度VR全景，让你在虚拟的 世界中体验现实发生的一切，感受3D沉浸式交互体验</p>
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img02-1.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>创意轻松实现</p>
            <p>强大的工具，图形化界面，简单易用，无需专业基础也可以快速创作属于自己的世界，让创意不在只是创意</p>
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img03-1.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>自由的社区</p>
            <p>聊天，交友，组队一起探索，在这里你可以与小伙伴一起体验和创造世界，随时随地的交流互动</p>
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img04-1.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <!-- <p>创意也可以变现</p> -->
            <p>创意任意分享</p>
            <!-- <p>创意不仅是有趣，还可以为自己带来收入，所有玩家创造的内容都可以交易出售</p> -->
            <p>将有趣的创意分享给他人，带来更多成就感</p>
          </div>
        </div>
      </div>
    </v-card>

    <v-card class="warps warp3" :flat="true" width="100%" height="214" img="https://h5.ophyer.cn/official_website/other/metaverse-h5-home-footer-bg2.png">
      <div class="con">
        <p>快来加入创作队伍吧</p>
        <!-- <button @click="currentHover = true"> 下载编辑器 </button> -->
      </div>
    </v-card>

    <div class="dialog" v-show="currentHover" @click.stop="currentHover = false">
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-tryout" @click.stop>
          <v-card color="transparent" flat @click.stop>
            <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHover = false">
              <img width="11px" height="12px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
            </v-card-title>
            <img style="min-height: 180px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-bg.png" alt="">
            <div class="box">
              <img src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-icon.png" alt="">
              <div>
                <p>010-63037996</p>
                <p>客服热线</p>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </div>

  </v-app>
</template>

<script>


export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      screenHeight: window.innerHeight,
      addClass: false,
      currentHover: false,
      warp1: [
        {
          title: '创造你的领地',
          text: '提供强大的可视化的创意工具，简易的拖拽即可搭建720度VR场景，轻松创造3D素材。在这里，你可以创造出你想要的一切',
          bg: 'https://h5.ophyer.cn/official_website/other/metaverse-home-warp1-img-z1-02.png',
          btn: '下载编辑器',
          more: '了解更多',
          router: "/h5/develop/Home",
          isNewWindow: true,
        },
        {
          title: '飞天元宇宙',
          text: '你可以与好友、陌生人，一起随心所欲的探索这个世界，做你想做的一切。加入我们一起来让世界更加丰富多彩。',
          bg: 'https://h5.ophyer.cn/official_website/other/metaverse-home-warp1-img-z2-02.png',
          // more: '去体验',
          router: "/h5/Course",
          isNewWindow: true,
        }
      ],
 
 
    }
  },
  created() {
    // console.log(this.screenHeight)
  },
  mounted() {
    new this.$wow.WOW().init()
  },
  methods: {
    handleLink(content) {
      if(content.isNewWindow){
        window.open(window.location.protocol + "//" + window.location.host + content.router)
      }else {
        this.$router.push(content.router)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.v-application, .v-card {
  background: #191b1f;
  overflow-x: hidden !important;
}
.banner{
  position: relative;
  overflow: hidden;
  width: 100%;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
    background: #191b1d !important;
  }
  #video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
  }
  .video-up {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0,0,0,.2);
  }
  .banner-box {
    width: 100%;
    position: absolute;
    z-index: 3;
    color: #fff;
    top: 50%;
    transform: translate(0%, -50%) !important;
    text-align: center;
    margin-top: -110px;
    .banner-title {
      // font-size: 56px;
      font-size: 40px;
      font-weight: 600;
      line-height: 78px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
      position: relative;
      z-index: 3;
      margin-bottom: 10px;
    }
    .banner-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
      position: relative;
      z-index: 3;
    }
    &::after {
      content: "";
      background: url('https://h5.ophyer.cn/official_website/other/banner-bgs2.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  .aaa {
    animation: opacity-one 2s 1;
    // transition-delay: 2s;
    // opacity: 1;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    z-index: 11;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(22,22,26,0)),color-stop(85%,#191b1d));
    background-image: linear-gradient(rgba(22,22,26,0),#191b1d 85%);
    background-origin: padding-box;
    background-size: auto;
  }
}

.metaverse-title {
  margin-top: 40px;
  color: #FFFFFF;
  text-align: center;
  position: relative;
  z-index: 6;
  padding: 0 20px;
  margin-bottom: 20px;
  p:nth-child(1) {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
  }
  p:nth-child(2) {
    margin: 10px auto 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
}


.warps {
  margin: 0 auto;
}

.warp1 {
  position: relative;
  z-index: 2;
  .top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    z-index: 11;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: -webkit-gradient(linear,left bottom,left top,from(rgba(22,22,26,0)),color-stop(85%,#191b1d));
    // background-image: linear-gradient(rgba(22,22,26,0),#191b1d 85%);
    background-origin: padding-box;
    background-size: auto;
  }
  .con {
    // display: flex;
    position: relative;
  }
  .div-filter {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.25);
    backdrop-filter: saturate(180%) blur(6px);
    -webkit-backdrop-filter: saturate(180%) blur(6px);
    border-radius: 5px;
    // border-right: 1px solid #7b828d;
    border: 1px solid #7b828d;

  }
  .v-card {
    margin-top: 20px;
    border-radius: 5px;
    // border: 1px solid #7b828d;
    padding: 18px 0 20px 15px;
    background: unset !important;
    position: relative;
    z-index: 4;
    transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1);
    background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%) !important;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 3;
      width: 161px;

    }
    .box {
      position: relative;
      z-index: 6;
    }
    .v-card__title {
      font-size: 18px !important;
      color: #FFFFFF !important;
      padding: 0 !important;
      margin-bottom: 18px;
    }
    .v-card__text {
      width: 95%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 22px;
      padding: 0 !important;
    }
    .more {
      display: inline-block;
      position: relative;
      z-index: 3;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 20px;
      &::after {
        content: "";
        background-image: url("./../../../assets/images/home/h5-case-icon.png");
        background-size: 100%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -27px;
        top: 3px;
      }
    }
    .btn {
      margin-top: 30px;
      height: 40px;
      button {
        color: #FFFFFF;
        font-size: 14px;
        width: 110px;
        height: 40px;
        background: #191626;
        border-radius: 5px;
        border: 1px solid #dcdce0;
      }
    }
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    content: "";
    width: 100%;
    height: 100%;
    background: url("https://h5.ophyer.cn/official_website/other/metaverse-h5-home-bg01.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
}


.warp2 {
  margin-top: 10px;
  width: 100%;
  position: relative;
  .con {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }
  .box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    margin: 40px auto;
  }
  .box-1 {
    .box-img {
      width: 100%;
      // height: 270px;
      // background: pink;
      // border-radius: 10px;
      img {
        width: 100%;
        height: 270px;

      }
    }
    .box-text {
      margin-top: 37px;
      p:nth-child(1) {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        position: relative;
        margin-bottom: 0;
        &::after {
          content: '';
          width: 40px;
          height: 3px;
          background: #FFFFFF;
          position: absolute;
          left: 0;
          top: -16px;
        }
      }
      p:nth-child(2) {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 300;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.warp3 {
  margin: 0 auto;
  .con {
    text-align: center;
    padding-top: 20px;
    p {
      font-size: 20px;
      font-weight: 500;
      color: #FFFFFF;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
    button {
      color: #FFFFFF;
      font-size: 14px;
      width: 128px;
      height: 46px;
      background: #191626;
      border-radius: 5px;
      border: 1px solid #dcdce0;
    }
  }
}


.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 80%;
    max-width: 380px;
    height: 270px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.6) !important;
      cursor: pointer;
    }
    .box {
      margin-top: 15px;
      display: flex;
      align-items: center;
      margin-left: 35px;
      img {
        width: 48px;
        height: 48px;
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      p:nth-child(2) {
        margin-top: 5px !important;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

</style>
